@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~animate.css/animate.min.css';
@import '~@bidalihq/ui/dist/variables.css';
@import '~@bidalihq/ui/dist/components/animate/animate.css';
@import '~@bidalihq/ui/dist/components/select/select.css';

/* @media (min-width: 1024px) { */
  html.scroll-snap {
    scroll-snap-type: y mandatory;
    scroll-snap-stop: always;
    scroll-behavior: smooth;
  }

  html.scroll-snap body {
    scroll-snap-type: y mandatory;
    scroll-snap-stop: always;
    scroll-behavior: smooth;
  }

  .prose {
    max-width: 100%
  }
/* } */

.home {
  background-image: url('/images/background.jpeg');
  background-size: contain;
  background-repeat: no-repeat;
}

#iFrameResizer0,
#successMessageDiv,
#errorMessageDiv {
  height: 100%;
  width: 100%;
}